<template>
	<svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21.8404 4.57564L4.57565 21.8403" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path d="M13.208 1V3.7124M4.57563 4.57564L6.49358 6.4936M1 13.208H3.7124" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path opacity="0.35" d="M8.53621 1.92928L9.5742 4.43522M1.92929 8.5362L4.43522 9.57419M1.9293 17.8798L4.43523 16.8418M17.8798 1.92929L16.8418 4.43522" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path opacity="0.35" d="M18.3875 8.02858L21.8404 8.89181" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path opacity="0.35" d="M8.89185 21.8403L8.02861 18.3874" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path d="M25 16.3677L13.2079 13.208" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path d="M22.8419 12.6297L20.2831 15.1038L21.262 18.5257" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path d="M16.3676 25L13.2079 13.208" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path d="M18.5257 21.2621L15.1037 20.2832L12.6297 22.8419" :stroke="color" stroke-width="2" stroke-linecap="round"/>
		<path d="M8.93519 17.4808C6.57539 15.121 6.57539 11.295 8.93519 8.93519C11.295 6.57539 15.121 6.57539 17.4808 8.93519" :stroke="color" stroke-width="2"/>
	</svg>
</template>

<script>
  	export default {
    	name: 'moduleH',
    	props: ['color'],
    }
</script>