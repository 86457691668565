<template>
  
  <TopMenu :breadcrumbs="breadcrumbs"/>

	<div class="load-container" :class="{'load' : isLoad}">

	  <div class="block" v-if="organizations.length > 0">
	  	<div class="block__header">
	  		Управляющие организации
	  	</div>
	  	<div class="block__body">
	  
			  <table class="table-custom" >
			  	<thead>
			  		<tr>
			  			<th>Наименование</th>
			  		</tr>
			  	</thead>
			  	<tbody>
			  		<tr v-for="organization in organizations" :key="organization.id" @click="$router.push({name: 'PageAnalyticsOrg', params: {organization_id: organization.id}})">
			  			<td> {{ organization.name }} </td>
			  		</tr>
			  	</tbody>
			  </table>
			
	  	</div>
	  </div>

	  <div class="empty-data" v-else>
	  	Нет данных
	  </div>

	</div>

</template>


<script setup>
  import TopMenu from '@/components/TopMenu.vue'
</script>

<script>
	export default {
    name: 'PageAnalytics',
    data: () => ({
    	breadcrumbs: [],
    	organizations: [],
	    isLoad: true,
    }),
    created () {
      Promise.all([this.loadOrganizations()])
      .then(() => {
        this.beforeRender();
      })
      .catch((e)=> {
        console.log(e);
      })
      .finally(() => {
	      	this.isLoad = false;
		  });
    },
    methods: {
      beforeRender () {
        // Формируем крошки
        this.breadcrumbs = [
          { name: this.$route.meta.name, link: this.$route.path, ico: this.$route.meta.ico },
        ];
      },
      async loadOrganizations () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/companies/');
        this.organizations = r.data.data;
      },
    },
	}
</script>

<style scoped>
	table tbody tr {
		cursor: pointer;
	}
</style>