<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M12 18H20.0839C20.2657 17.9971 20.4429 17.9338 20.5933 17.818C20.7437 17.7021 20.8608 17.5388 20.93 17.3483C20.9993 17.1577 21.0176 16.9484 20.9828 16.7461C20.9481 16.5439 20.8616 16.3577 20.7343 16.2105L17.9468 12.5789H18.2255C18.4074 12.5761 18.5845 12.5128 18.7349 12.3969C18.8853 12.2811 19.0024 12.1177 19.0717 11.9272C19.1409 11.7367 19.1593 11.5273 19.1245 11.3251C19.0897 11.1228 19.0033 10.9366 18.8759 10.7895L16.0884 7.31579H16.2742C16.464 7.33518 16.6545 7.28801 16.8198 7.18065C16.9851 7.07329 17.1173 6.91095 17.1985 6.71562C17.2796 6.52029 17.3058 6.30143 17.2734 6.08869C17.241 5.87595 17.1517 5.67962 17.0176 5.52632L13.3009 1L10.5 4.5M13 18V21" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path d="M6 13V21M8.78991 10.1051C8.92549 9.76309 9 9.39025 9 9C9 7.34315 7.65685 6 6 6C4.34315 6 3 7.34315 3 9C3 9.39025 3.07451 9.76309 3.21009 10.1051C1.93647 10.4518 1 11.6165 1 13C1 14.6569 2.34315 16 4 16C5.57751 16 6.42249 16 8 16C9.65685 16 11 14.6569 11 13C11 11.6165 10.0635 10.4518 8.78991 10.1051Z" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
  	export default {
    	name: 'moduleG',
    	props: ['color'],
    }
</script>