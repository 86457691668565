<template>
	<div class="tooltip">
        
        <div class="tooltip__content" @mouseover="isOpen = true" @mouseleave="isOpen = false">
        	<slot name="content"></slot>
        </div>

        <transition name="slide-fade">
	        <div class="tooltip__info" v-if="isOpen">
	        	<slot name="info"></slot>
	        </div>
        </transition>

	</div>
</template>

<script setup>

</script>

<script>
    export default {
        name: 'TooltipContainer',
        props: ['size'],
        data: () => ({
            isOpen: false,
        }),
        methods: {
        },
        computed: {
        }
    }
</script>

<style scoped>

	.tooltip {
		position: relative;
	}

	.tooltip__content {
		display: flex;
		cursor: help;
	}

	.tooltip__info {
		position: absolute;
	    top: calc(100% + 8px);
	    left: 0px;
	    max-width: 300px;
	    width: max-content;
	    white-space: normal;
	    background: #FFFFFF;
	    padding: 8px 12px;
	    font-size: 11px;
	    font-weight: 500;
	    line-height: 1.1;
		box-shadow: 0px 5px 15px rgba(11, 40, 54, 0.15);
		border-radius: 8px;
	}

	.slide-fade-enter-active {
	  transition: all 0.2s ease-out;
	}
	.slide-fade-leave-active {
	  transition: all 0.2s ease-out;
	}
	.slide-fade-enter-from,
	.slide-fade-leave-to {
	  transform: translateX(30px);
	  opacity: 0;
	}



</style>