<template>

  <TopMenu :breadcrumbs="breadcrumbs"/>

  <div class="load-container" :class="{'load' : isLoad}">
    <template v-if="companies.length > 0">
      <template v-if="companies.length > 1">
        <CollapseContainer v-for="company in companies" :key="company.id" class="collapse--transparent">
          <template v-slot:header>
            <div class="c-collapse-badge"> 
              {{ company.houses.length }} 
            </div>
            {{ company.name }}
          </template>
          <template v-slot:body>
            <HousePreview :house="house" :company="company" v-for="house in company.houses" :key="house.id"/>
          </template>
        </CollapseContainer>
      </template>
      <template v-else>
        <HousePreview :house="house" :company="companies[0]" v-for="house in companies[0].houses" :key="house.id"/>
      </template>
    </template>
	</div>

</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue';
  import HousePreview from '@/components/reports/HousePreview.vue';
  import CollapseContainer from '@/components/ux/CollapseContainer.vue';
</script>

<script>

  export default {
    name: 'PageReports',
    data: () => ({
      isLoad: false,
      breadcrumbs: [],
      companies: [],
    }),
    
    created () {
      this.breadcrumbs = [
        {name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico}
      ];
      this.loadData();
    },

    methods: {
    	beforeRender () {},
    	async loadData () {
        this.isLoad = true;
        this.companies = await this.$store.dispatch('getReportsCompany');
        this.isLoad = false;
    	},
    },

  }
</script>


<style scoped>
  .block {
    margin-bottom: 10px;
  }
  .collapse:not(:last-child) {
    margin-bottom: 5px;
  }
  .c-collapse-badge {
    background: #E9F1F4;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.05em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: var(--cozh-blue);
  }
  .collapse:deep(.collapse__header) {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0B2836;
    word-wrap: break-word;
  }



</style>