<template>
	<TopMenu :breadcrumbs="breadcrumbs"/>
  <div class="load-container" :class="{'load' : isLoad}">
    <div class="content" v-if="!isLoad">
      <div class="block" v-if="organizations.length === 1">
        <div class="block__header">
          Дома
        </div>
        <div class="block__body">
          <InfoHouses :organization_id="organizations[0].id"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import InfoHouses from '@/components/organization/InfoHouses.vue'
</script>

<script>
  export default {
    name: 'PageHouses',
    data: () => ({
      isLoad: true,

      organizations: [],
      breadcrumbs: [],
    }),
    created () {
      Promise.all([this.loadOrganizations()])
      .then(() => {
        this.beforeRender();
        this.isLoad = false;
      })
      .catch((e)=> { console.log(e) })
    },

    methods: {
      beforeRender () {
        // Формируем крошки
        this.breadcrumbs = [
          { name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico },
        ];
      },
      async loadOrganizations () {
        const r = await this.axios.get(this.$store.state.api_endpoint + '/companies/');
        this.organizations = r.data.data;
      },
    },
  }
</script>

<style scoped>
  .block {
    margin-bottom: 10px;
  }
</style>