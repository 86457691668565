<template>
	<div class="content">
		<div class="load-container" :class="{'load' : isLoad}">
			<table class="table-custom" v-if="items.length > 0">
				<thead>
					<tr>
						<th> Логин </th>
						<th> Фио </th>
						<th> Роль </th>
						<th> Квалификация </th>
						<th> Город </th>
						<th> Дома </th>
						<th> Контакт </th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in items" :key="item.id" @click="goToEmployee(item.id)">
						<td class="nowrap">
							<span v-if="item.login == null || item.login.length == 0"> - </span>
			  			<span v-else> {{ item.login }} </span>
						</td>
						<td class="nowrap">
							<span v-if="item.name == null || item.name.length == 0"> - </span>
			  			<span v-else> {{ item.name }} </span>
						</td>
						<td class="nowrap">
							<span v-if="item.roleId == null || item.roleId.length == 0"> - </span>
			  			<span v-else> {{ item.roleId }} </span>
						</td>
						<td class="relative">
							<span v-if="item.functions.length == 0"> - </span>
							<span v-else v-for="(func, index) in item.functions" :key="func.id"> 
								{{ func.description }}{{ index != item.functions.length - 1 ? ', ' : '' }}
							</span>
						</td>
						<td class="relative">
							<span v-if="item.cities.length == 0"> - </span>
							<span v-else v-for="(city, index) in item.cities" :key="city.id"> 
								{{ city.name }}{{ index != item.cities.length - 1 ? ', ' : '' }}
							</span>
						</td>
						<td class="relative">
							<span v-if="item.houses.length == 0"> - </span>
							<span v-else v-for="(house, index) in item.houses" :key="house.id"> 
								{{ house.address }}{{ index != item.houses.length - 1 ? ', ' : '' }}
							</span>
						</td>
						<td>
							<span v-if="item.contact == null || item.contact.length == 0"> - </span>
			  			<span v-else> {{ item.contact }} </span>
						</td>
					</tr>
				</tbody>
			</table>
			<div class="empty-data" v-else>Нет данных</div>
		</div>
		<div class="content__footer">
			<router-link class="btn btn--outline-grey btn--sm" :to="{name: 'PageEmployeeCreate', params: {organization_id: organization_id}}">
				<img class="btn--ico btn--ico--left" src="@/assets/img/2.svg">
				<div class="btn--text"> Добавить сотрудника </div>
			</router-link>
		</div>
  </div> 
</template>
<script setup>
</script>
<script>
  export default {
    name: 'InfoEmployees',
    props: ['organization_id'],
    data: () => ({
    	isLoad: true,
    	items: [],
    }),
    async created () {
    	await this.loadItems();
    	this.isLoad = false;
    },
    methods: {
    	async loadItems () {
        try {
          let r = await this.axios.get(this.$store.state.api_endpoint + '/employees/?companyId=' + this.organization_id + '&detailed=true');
          if(r.status == 200) {
            this.items = r.data.data;
          }
        } catch (e) {e}
      },
      goToEmployee (id) {
      	this.$router.push({name: 'PageEmployeeMain', params: {organization_id: this.organization_id, employee_id: id}})
      }
    },
  }
</script>

<style scoped>
	.content {
		padding: 0px 0px;
	}
	.content__footer {
		padding: 20px 25px;
		border-top: 1px solid var(--cozh-light-grey);
	}
	.table-custom tr {
		cursor: pointer;
	}
</style>