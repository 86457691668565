<template>
	<TopMenu :breadcrumbs="breadcrumbs"/>
	<div class="load-container" :class="{'load' : isLoad}">
  	<div class="content" v-if="!isLoad && !isError">
      <template v-if="companies.length > 1">
        <CollapseContainer v-for="company in companies" :key="company.id" class="collapse--transparent">
          <template v-slot:header>
            <div class="c-collapse-badge"> 
              {{ company.houses.length }} 
            </div>
            {{ company.name }}
          </template>
          <template v-slot:body>
              <HousePreview :house="house" :company="company" v-for="house in company.houses" :key="house.id"/>
          </template>
        </CollapseContainer>
      </template>
      <template v-else>
        <HousePreview :house="house" :company="companies[0]" v-for="house in companies[0].houses" :key="house.id" />
      </template>
  	</div>
  	<ErrorContent  v-else/>
	</div>
</template>

<script setup>
  import TopMenu from '@/components/TopMenu.vue'
  import ErrorContent from '@/components/ErrorContent.vue'
  import HousePreview from '@/components/tracker/HousePreview.vue'
  import CollapseContainer from '@/components/ux/CollapseContainer.vue'
</script>

<script>

  export default {
    name: 'PageTracker',

    data: () => ({
      isLoad: true,
      isError: false,
      breadcrumbs: [],
      companies: null,
    }),

    computed: {
    
    },

    created () {
      
      this.breadcrumbs = [
          {name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico}
      ];

      Promise.all([this.loadData()])
        .then(() => {
          this.beforeRender();
          this.isLoad = false;
        })
        .catch((e)=> {
          console.log(e);
          this.isError = true;
          this.isLoad = false;
        })
    },

    methods: {
    	beforeRender () {
    	},
      async loadData () {
        const { companies } = await this.$store.dispatch('getTrackerObjects');
        this.companies = companies;
      },
    },
  }
</script>

<style scoped>
  .block {
    margin-bottom: 10px;
  }
  .collapse:not(:last-child) {
    margin-bottom: 5px;
  }
  .c-collapse-badge {
    background: #E9F1F4;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.05em;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
    color: var(--cozh-blue);
  }
  .collapse:deep(.collapse__header) {
    font-weight: 600;
    font-size: 12px;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #0B2836;
    word-wrap: break-word;
  }
</style>