<template>
	<CollapseContainer ref="collapse">
    <template v-slot:header>
    	<div class="c-collapse">
				<div class="c-collapse__left">
					<div class="c-collapse__badge" :class="{'active': topic.active}"></div>
					<span class="c-collapse__label me-1 ms-1" :for="topic.num">{{ topic.num }}</span> 
					{{ topic.name }}
				</div>
				<div class="c-collapse__right">
					<div class="c-collapse__buttons">
						<a class="btn btn--sm btn--default" @click="saveTopic($event, topic)">
	            <img class="btn--ico btn--ico--left" src="@/assets/img/check-white.svg">
	            <div class="btn--text"> Сохранить шаблон </div>
	            <div class="btn--loader"></div>
		        </a>
					</div>
					<div class="c-collapse__message">
						<span v-if="topic.active"> Шаблон сохранён </span>
						<span v-else class="nowrap"> Шаблон не сохранён </span>
					</div>
				</div>
			</div>
    </template>
    <template v-slot:body>
    	<template v-for="(loc_lvl_1,  key_lvl_1) in topic.locations" :key="key_lvl_1">
				<template v-if="key_lvl_1 !== 'default'">
					<!-- Локации первого уровня -->
					<div class="location" v-for="loc in loc_lvl_1" :key="loc.id">
						<div class="location__header">
							<img src="@/assets/img/place.svg">
							<span> {{ loc.title }} </span> 
						</div>
						<div class="location__body">
							<template v-for="(loc_lvl_2, key_lvl_2) in loc.locations" :key="key_lvl_2">
								<template v-if="key_lvl_2 !== 'default'">
									<!-- Локации второго уровня -->
									<div class="location" v-for="loc in loc_lvl_2" :key="loc.id">
										<div class="location__header">
											<img src="@/assets/img/place.svg">
											<span> {{ loc.title }} </span> 
										</div>
										<div class="location__body">
											<GroupTemplate 
												:topic="loc" 
												:functions="functions" 
												:employees="employees" 
											/>
										</div>
									</div>
								</template>
								<!-- Дэфолт на втором уровне -->
								<template v-else>
									<GroupTemplate 
										:topic="loc_lvl_2[0]" 
										:functions="functions" 
										:employees="employees" 
									/>
								</template>
							</template>
						</div>
					</div>
				</template>
				<!-- Дэфолт на первом уровне -->
				<template v-else>
					<template v-for="(loc_lvl_2, key_lvl_2) in loc_lvl_1[0].locations" :key="key_lvl_2">
						<template v-if="key_lvl_2 !== 'default'">
							<!-- Локации второго уровня -->
							<div class="location" v-for="loc in loc_lvl_2" :key="loc.id">
								<div class="location__header">
									<img src="@/assets/img/place.svg">
									<span> {{ loc.title }} </span> 
								</div>
								<div class="location__body">
									<GroupTemplate 
										:topic="loc" 
										:functions="functions" 
										:employees="employees" 
									/>
								</div>
							</div>
						</template>
						<!-- Дэфолт на втором уровне -->
						<template v-else>
							<div class="location location--nongroup">
								<div class="location__header">
									<img src="@/assets/img/place.svg">
									<span> Задания без группировки </span> 
								</div>
								<div class="location__body">
									<GroupTemplate 
										:topic="loc_lvl_2[0]" 
										:functions="functions" 
										:employees="employees" 
									/>
								</div>
							</div>
						</template>
					</template>
				</template>
			</template>
    </template>
  </CollapseContainer>
	<MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />

</template>

<script setup>
  import GroupTemplate from '@/components/house/GroupTemplate.vue'
  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
  import CollapseContainer from '@/components/ux/CollapseContainer.vue'
</script>

<script>
	export default {
		name: 'TopicTemplate',
		props: ['topic', 'functions', 'employees'],
    expose: ['$refs'],

	  data: () => ({
	  }),
	  computed: {
      house_id () {
        return this.$route.params.house_id;
      },
    },
	  methods: {
	  	
	  	async saveTopic (e, topic) {

  			const btn = e.currentTarget;
    		const data = {templates:[]};

    		btn.classList.add('btn--load');

    		topic.isError = false;
    		
    		const dataTopic = this.$helpers.buildTopic(topic);

    		if(topic.isError) {

          await new Promise(r => setTimeout(r, 600));

    			this.$refs.collapse.$refs.header.scrollIntoView({
            behavior: 'smooth',
            block: "start"
          });

          this.$refs.messageDialogue.show({
            message: 'Невозмжно сохранить',
            text: 'Допущенны ошибки в блоке: <b>' + topic.num + '</b>',
            okButton: 'Закрыть',
          });

          btn.classList.remove('btn--load');

          return;
        }

    		data.templates.push(dataTopic);


    		try {
    			
    			await new Promise(r => setTimeout(r, 300));

    			await this.axios.post(this.$store.state.api_endpoint + '/houses/' + this.house_id + '/template/activate', data);

    			this.$refs.messageDialogue.show({
            message: 'Шаблон сохранен!',
            okButton: 'Продолжить',
          });

    			topic.active = true;

    		} catch (e) {

    			this.$refs.messageDialogue.show({
            message: e.message,
            okButton: 'Подтвердить',
          });

    		}
    		
    		btn.classList.remove('btn--load');

	  	}

	  } 
	}
</script>

<style scoped>

	.collapse:not(:last-child) {
		margin-bottom: 10px;
	}
	.collapse.open:not(:last-child) {
    margin-bottom: 10px;
  }
	.collapse:deep(.collapse__header) {
		padding: 0px 20px;
		padding-right: 60px;
	}
	.collapse:deep(.collapse__body) {
		padding: 0px;
	}




	.c-collapse {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    border-right: 1px solid var(--cozh-light-grey);
    min-height: 60px;
    padding: 12px 20px 12px 0px;
    gap: 30px;
	}

	.c-collapse__left {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
	}

  .c-collapse__message {
    font-size: 11px;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.05em;
    color: var(--cozh-grey);
    text-transform: uppercase;
  }






	.c-collapse__badge {
		height: 6px;
		width: 6px;
		min-height: 6px;
		min-width: 6px;
		background-color: var(--cozh-light-grey);
		border-radius: 50%;
	}
	.c-collapse__badge.active {
		background-color: var(--cozh-blue);
	}






	.c-collapse__label {
    	color: var(--cozh-grey);
    	font-size: 14px;
    	font-weight: 600;
	}




	.c-collapse__buttons {
		display: none;
	}
	.c-collapse__message {
		display: flex;
	}
	.collapse .collapse.open .c-collapse__buttons {
		display: flex;
	}
	.collapse .collapse.open .c-collapse__message {
		display: none;
	}





	.location {
	  border-top: 1px solid var(--cozh-light-grey);
	}
	.location:first-child {
	  border-top: none;
	}
	
	.location__header {
 		font-size: 14px;
	  font-weight: 500;
	  padding: 10px 15px;
	  border-bottom: 1px solid var(--cozh-light-grey);
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  justify-content: flex-start;
	  line-height: 1;
	  background: #e9f1f4;
	}
	.location__header img {
		margin-right: 5px;
	}
	
	.location .topic__header, 
	.location .topic__body {
    	padding-left: 35px;
    	padding-left: 35px;
	}

	.location .location {
		margin-left: 20px;
	}

	.location .location .location__header {
		background-color: transparent;
	}


	.location + .location--nongroup .location__header {
		display: flex!important;
	}

	.location--nongroup .location__header {
		display: none;
	}

</style>