<template>
    <popup-modal ref="popup" :size="'xmd'" @close="close">
        <div class="popup">
            <div class="popup__header">
                Счётчик расходов электроэнергии
            </div>
            <div class="popup__body">
                <div class="load-container" :class="{'load' : isLoad}">
                    <table class="table-custom">
                        <thead>
                            <tr>
                                <th class="nowrap">Дата</th>
                                <th class="nowrap">Время</th>
                                <th class="nowrap">Значение показателя, кВт</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, index) in items" :key="index">
                                <td>{{ $moment(item.dt, 'DD.MM.YYYY HH:mm').format('DD MMMM YYYY') }}</td>
                                <td>{{ $moment(item.dt, 'DD.MM.YYYY HH:mm').format('HH:mm') }}</td>
                                <td>{{ item.value }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="popup__footer">
                <a class="btn btn--default btn--sm" @click="close">
                  <div class="btn--text"> Закрыть </div>
                  <div class="btn--loader"></div>
                </a>
            </div>
        </div>
    </popup-modal>
</template>

<script>

import PopupModal from './PopupModal.vue'

export default {

    name: 'MessageDialogue',

    components: { PopupModal },

    data: () => ({
        isLoad: false,
        items: [],
    }),

    computed: {
      house_id () {
        return this.$route.params.house_id;
      },
    },

    methods: {
        close () {
            this.$refs.popup.close();
        },
        show(opts = {}) {
            opts;

            this.$refs.popup.open();
            
            this.isLoad = true;
            this.items = [];

            Promise.all([this.loadData()])
              .then(() => {
                this.isLoad = false;
              })
              .catch((e)=> {
                console.log(e);
                this.isLoad = false;
              })


        },

        async loadData () {
            const r = await this.axios.get(this.$store.state.api_endpoint + '/document/ugin/' + this.house_id);
            this.items = r.data.data.archive;
        },

    },
}
</script>

<style scoped>
    .popup__body {
        padding: 0px;
    }
</style>