<template>
	<div class="custom-periodical" :class="classObject" @click="clickSelect($event)" ref="parent">

	    <div class="custom-periodical__text"> {{ displayValue }} </div>
	    <span class="custom-periodical__label"> {{ label }} </span>

      	<transition name="slideY">
		    <div class="custom-periodical__content" ref="content" v-if="isOpen">
		    	<div class="custom-periodical__body">
		    		<div class="custom-periodical__row" v-if="list.length > 0">
		    			<div class="custom-periodical__col">
		    				<template v-for="item in list" :key="item.id">
		    					<div class="checkbox">
									<input :id="item.id + '-' + uniq" type="checkbox" :value="item.id" v-model="picked">
									<label :for="item.id + '-' + uniq"> {{ item.name }} </label>
								</div>
		    				</template>
		    			</div>
		    		</div>
		    		<div class="custom-periodical__empty" v-else>
		      			Совпадений не найдено
		      		</div>
		    	</div>
		    	<div class="custom-periodical__footer">
		    		<a class="btn btn--default btn--sm btn--inline" @click="saveValue">
		    			Выбрать
		    		</a>
		    	</div>
		    </div>
		</transition>

	</div>
</template>

<script>
	export default {
		name: 'WeekDayPicker',
		props: ['label', 'modelValue', 'list'],
		created () {
			this.picked = this.modelValue;
	    	this.uniq = "id" + Math.random().toString(16).slice(2);
		},
	    data: () => ({
	    	isTop: false,
	    	isOpen: false,
	    	uniq: null,
	    	picked: [],
	    }),
	    computed: {
	    	displayValue () {
	    		let string = '';
	    		this.modelValue.forEach((item, index) => {
	    			let idx = this.list.findIndex( (elem) => { return elem.id == item } );
	    			if(idx !== -1) {
	    				string += this.list[idx].name;
	    				string += index !== this.modelValue.length - 1 ? ', ' : '';
	    			}
	    		});
	    		return string;
	    	},
	    	classObject () {
	    		return {
	    			'custom-periodical--open': this.isOpen, 
	    			'custom-periodical--top': this.isTop, 
	    		}
	    	},
	    },
	    mounted () {
		    this.$nextTick(() => {
				window.addEventListener('click', this.clickEvent);
		    })
	    },
	    unmounted () {
	    	window.removeEventListener('click', this.clickEvent);
		},
	    methods: {
	    	clickEvent (e) {
			  	if(this.isOpen) {
				  	if(!this.$refs.parent.contains(e.target)) {
				  		this.isOpen = false;
				  	}
			  	}
	    	},
	    	calculateVertical () {
	    		const boundingContent = this.$refs.content.getBoundingClientRect();
	    		const boundingSelect = this.$refs.parent.getBoundingClientRect();
	    		if ((boundingSelect.bottom + boundingContent.height) > window.innerHeight) {
	    			this.isTop = true;
	    		} else {
	    			this.isTop = false;
	    		}
	    	},
	    	saveValue() {
	    		this.$emit('update:modelValue', this.picked);
	    		this.isOpen = false;
	    	},
	    	clickSelect (e) {
	    		if (this.isOpen) {
	    			if(this.$refs.content.contains(e.target)) return;
	    		}
	    		this.isOpen = !this.isOpen;
	    		if (this.isOpen) {
					this.picked = this.modelValue;
				    this.$nextTick(() => {
	    				this.calculateVertical();
	    			});
	    		}
	    	},
	    }
	}
</script>

<style scoped>
	.custom-periodical__empty {
		font-size: 12px;
		font-weight: 500;
	}
</style>