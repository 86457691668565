<template>
	<svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M21 7V3C21 1.89543 20.1046 1 19 1H11M21 7V15M21 7H16M1 7V3C1 1.89543 1.89543 1 3 1H11M1 7V15M1 7H6M21 15V19C21 20.1046 20.1046 21 19 21H11M21 15H16M1 15V19C1 20.1046 1.89543 21 3 21H11M1 15H6M11 7V1M11 7H16M11 7H6M11 15V21M11 15H16M11 15H6M16 15V7M6 15V7" :stroke="color" stroke-width="2" stroke-linejoin="round"/>
	</svg>
</template>

<script>
  	export default {
    	name: 'moduleC',
    	props: ['color'],
    }
</script>