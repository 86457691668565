<template>

	<div class="load-container" :class="{'load' : isLoad}">

		<table class="table-custom" v-if="employees.length > 0">
		  	<thead>
		  		<tr>
		  			<th>% выполненных работ</th>
		  			<th>ФИО сотрудника</th>
		  			<th>Должность</th>
		  			<th>Управляющий</th>
		  		</tr>
		  	</thead>
		  	<tbody>
		  		<tr v-for="(employee) in employees" :key="employee.id">
		  			<td> 
		  				<span class="number">{{ employee.donePercent + '%' }}</span> 
		  			</td>
		  			<td class=""> 
		  				<template v-if="employee.emplLastName !== null && employee.emplLastName.length !== 0">
		  					{{employee.emplLastName.charAt(0).toUpperCase() + employee.emplLastName.slice(1).trim()}}
		  				</template>
		  				<template v-if="employee.emplFirstName !== null && employee.emplFirstName.length !== 0">&nbsp;{{employee.emplFirstName.trim().charAt(0).toUpperCase()}}.</template>
		  				<template v-if="employee.emplMiddleName !== null && employee.emplMiddleName.length !== 0">&nbsp;{{employee.emplMiddleName.trim().charAt(0).toUpperCase()}}.</template>
		  			</td>
		  			<td>
		  				<span v-if="employee.functions.length == 0"> - </span>
							<HiddenText :text="functionString(employee.functions)" :limited="50"/>
		  			</td>
		  			<td>
		  				<template v-for="(manager, index) in employee.managers" :key="index">
		  					<template v-if="manager.lastName !== null && manager.lastName.length !== 0">{{manager.lastName.trim()}}</template>
			  				<template v-if="manager.firstName !== null && manager.firstName.length !== 0">&nbsp;{{manager.firstName.trim().charAt(0).toUpperCase()}}.</template>
			  				<template v-if="manager.middleName !== null && manager.middleName.length !== 0">&nbsp;{{manager.middleName.trim().charAt(0).toUpperCase()}}.</template>{{ index != employee.managers.length - 1 ? ', ' : '' }}
		  					<br />
		  				</template>
		  			</td>
		  		</tr>
		  	</tbody>
		</table>

		<div class="empty-data" v-else>
		  	Нет данных
		</div>
	</div>
</template>

<script setup>
  import HiddenText from '@/components/ux/HiddenText.vue';
</script>

<script>
  	export default {
	    name: 'WorstIndicator',
	    props: ['organization_id'],
	    data: () => ({
	    	isLoad: true,
	    	employees: [],
	    }),

	    created () {
	      Promise.all([this.loadTopBad()])
	      .then(() => {
	      })
	      .catch((e)=> {
	        console.log(e);
	      })
	      .finally(() => {
	      	this.isLoad = false;
		  });
	    },

	    computed: {
	    	functionString () {
	    		return (functions) => {
	    			let str = '';
	    			functions.forEach((func, idx) => {
	    				str += func;
	    				if(idx != functions.length - 1) {
	    					str += ', ';
	    				}
	    			});
	    			return str;
	    		}
	    	}
	    },

	    methods: {
	    	async loadTopBad () {
		        const r = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/top-bad?company_id=' + this.organization_id);
		        this.employees = r.data.employees;
	    	}
	    }

	}
</script>

<style scoped>
	.number {
		color: var(--cozh-red);
		font-weight: 600;
	}
</style>