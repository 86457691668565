<template>
	<div class="load-container" :class="{'load' : isLoad}">

		<grid-table
			:options="{
        fields: [
        	{key: 'emplLastName', title: 'ФИО сотрудника'},
        	{title: 'Должность'},
        	{key: 'totalTasks', title: 'Всего заданий'},
        	{key: 'donePercent', title: 'Выполненно,&nbsp;%'},
        	{key: 'expiredPercent', title: 'Просрочено,&nbsp;%'},
        	{title: 'Управляющий'},
        ],
        indexField: '№',
    		defaultSort: {
	    		key: 'donePercent',
	    		sort: 'desc',
	    	},
        items: items,
			}"
		>

      <template v-slot:default="{ item }">
        <td> <span class="nowrap"> {{ buildFio(item.emplLastName, item.emplFirstName, item.emplMiddleName) }} </span> </td>
        <td>
        	<span v-if="item.functions.length == 0"> - </span>
        	<span v-else> {{ buildFunctions(item) }}</span>
        </td>
        <td> <span class="number"> {{item.totalTasks}} </span> </td>
        <td> <span class="number number--blue"> {{item.donePercent + '%'}} </span> </td>
        <td> <span class="number number--dark-red"> {{item.expiredPercent + '%'}} </span> </td>
        <td>
        	<template v-for="(manager, index) in item.managers" :key="index">
        		<span class="nowrap">{{ buildFio(manager.lastName, manager.firstName, manager.middleName) }}</span>{{ index != item.managers.length - 1 ? ', ' : '' }}
        		<br />
	  			</template>
        </td>
      </template>

	  </grid-table>


	</div>
</template>

<script setup>
  import GridTable from '@/components/ux/FastGridTable.vue';
</script>

<script>

  	export default {

	    name: 'EmployersStat',

	    props: ['organization_id', 'begin', 'end'],

      expose: ['loadData'],
	    
	    data: () => ({
	    	isLoad: true,
	    	items: [],
	    }),

	    created () {
	      	this.loadData();
	    },

	    computed: {
	    	buildFio () {
	    		return (f, i, o) => {
	  				let fio = '';
	  				// Фамилия с большой буквы
	  				if(f !== null && f.length !== 0) {
	  					fio += f.charAt(0).toUpperCase() + f.slice(1).trim();
	  				}
	  				// Первая буква имени - большая
	  				if(i !== null && i.length !== 0) {
	  					fio += ' ' + i.trim().charAt(0).toUpperCase() + '.';
	  				}
	  				// Первая буква отчества - большая
	  				if(o !== null && o.length !== 0) {
	  					fio += ' ' + o.trim().charAt(0).toUpperCase() + '.';
	  				}
	    			return fio;
	    		}
	    	},
	    	buildFunctions () {
	    		return (item) => {
	    			let str = '';
	    			item.functions.forEach((func, idx) => {
	    				str += func;
	    				if(idx != item.functions.length - 1) {
	    					str += ', ';
	    				}
	    			});
	    			return str;
	    		}
	    	}
	    },

	    methods: {

	    	buildfilter () {
		        const param = new URLSearchParams({});
		        param.set("company_id", this.organization_id);
		        if(this.begin !== null) {
		          param.set("begin", this.begin + 'T00:00:00.000000+00:00');
		        }
		        if(this.end !== null) {
		          param.set("end", this.end + 'T23:59:59.999999+00:00');
		        }
		        return param;
		    },
		    
	    	async loadData () {
	    		this.isLoad = true;
	    		const param = this.buildfilter();
		      const {data} = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/report-employee-director?' + param);
		      this.items = data.employees;
	    		this.isLoad = false;
	    	},

	    },

	}

</script>

<style scoped>
	.number {
		font-weight: 600;
	}
	.number--orange {
		color: var(--cozh-orange);
	}
	.number--blue {
		color: var(--cozh-blue);
	}
	.number--red {
		color: var(--cozh-red);
	}
	.number--dark-red {
		color: var(--cozh-dark-red);
	}
</style>