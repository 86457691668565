<template>
	
	<div class="content">
		
		<div class="load-container" :class="{'load' : isLoad}">

			<table class="table-custom" v-if="items.length > 0">
				<thead>
					<tr>
						<th class="nowrap"> Город </th>
						<th class="nowrap"> Адрес </th>
						<th class="nowrap"> Год ввода <br /> в эксплуатацию </th>
						<th class="nowrap"> Подключенные модули </th>
						<th class="nowrap"> ФИО управляющего домом </th>
						<th class="nowrap"> Контакт </th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in items" :key="item.id" @click="goToHouse(item.id)">
						<td class="nowrap"> 
							<span v-if="item.city == null"> - </span>
			  			<span v-else> {{ item.city.name }} </span>
						</td>
						<td class="nowrap"> 
							<span v-if="item.address == null || item.address.length == 0"> - </span>
			  			<span v-else> {{ item.address }} </span>
						</td>
						<td class="nowrap">
							<span v-if="item.commissioningYear == null || item.commissioningYear.length == 0"> - </span>
			  			<span v-else> {{ item.commissioningYear }} </span>
						</td>
						<td> 
							<span v-if="item.module.length == 0"> - </span>
							<span v-else v-for="(m, index) in item.module" :key="m.id"> 
								{{ m.name }}{{ index != item.module.length - 1 ? ', ' : '' }}
							</span>
						</td>
						<td > 
							<span v-if="item.manager == null"> - </span>
			  			<span v-else> {{ item.manager.name }} </span>
						</td>
						<td class="nowrap"> 
							<span v-if="item.manager == null || item.manager.contact == null"> - </span>
			  			<span v-else> {{ item.manager.contact }} </span>
						</td>
					</tr>
				</tbody>
			</table>
			
			<div class="empty-data" v-else> Нет данных </div>

		</div>

		<div class="content__footer">
			<router-link class="btn btn--outline-grey btn--sm" :to="{name: 'PageHouseCreate', params: {organization_id: organization_id}}">
				<img class="btn--ico btn--ico--left" src="../../assets/img/2.svg">
				<div class="btn--text"> Добавить дом </div>
			</router-link>
		</div>
  </div>
</template>

<script setup>
</script>

<script>
  export default {
    name: 'InfoHouses',
    props: ['organization_id'],
    data: () => ({
    	isLoad: true,
    	items: [],
    }),
    async created () {
    	await this.loadItems();
    	this.isLoad = false;
    },
    methods: {
    	async loadItems () {
        try {
          let r = await this.axios.get(this.$store.state.api_endpoint + '/houses/?companyId=' + this.organization_id + '&detailed=true');
          if(r.status == 200) {
            this.items = r.data.data;
          }
        } catch (e) {e}
      },
      goToHouse (id) {
      	this.$router.push({name: 'PageHouseMain', params: {organization_id: this.organization_id, house_id: id }})
      }
    },
  }
</script>

<style scoped>
	.content {
		padding: 0px 0px;
	}
	.content__footer {
		padding: 20px 25px;
		border-top: 1px solid var(--cozh-light-grey);
	}
	.table-custom tr {
		cursor: pointer;
	}
</style>