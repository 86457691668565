<template>
	<svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M5.25 10.0343H3.25C2.71957 10.0343 2.21086 9.82273 1.83579 9.44623C1.46071 9.06973 1.25 8.55909 1.25 8.02664V3.00761C1.25 2.47516 1.46071 1.96452 1.83579 1.58802C2.21086 1.21152 2.71957 1 3.25 1H19.25C19.7804 1 20.2891 1.21152 20.6642 1.58802C21.0393 1.96452 21.25 2.47516 21.25 3.00761V8.02664C21.25 8.55909 21.0393 9.06973 20.6642 9.44623C20.2891 9.82273 19.7804 10.0343 19.25 10.0343H17.25M17.55 16.7559C17.9731 17.2 18.2192 17.7845 18.2416 18.3985C18.2641 19.0126 18.0613 19.6136 17.6717 20.0876C17.2821 20.5615 16.7328 20.8756 16.128 20.9701C15.5231 21.0647 14.9047 20.9331 14.39 20.6005C14.0553 20.3802 13.7775 20.0834 13.5793 19.7344C13.3812 19.3854 13.2683 18.9942 13.25 18.5929V10.0343M5.85 14.648C5.6137 14.8805 5.43859 15.1683 5.34035 15.4855C5.24212 15.8027 5.22383 16.1394 5.28713 16.4655C5.35042 16.7915 5.49333 17.0967 5.70304 17.3537C5.91275 17.6107 6.1827 17.8114 6.4887 17.9379C6.7947 18.0644 7.12718 18.1126 7.45634 18.0784C7.7855 18.0442 8.10106 17.9285 8.37472 17.7417C8.64838 17.5549 8.8716 17.3029 9.02436 17.0082C9.17712 16.7136 9.25465 16.3854 9.25 16.0533V10.0343" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path opacity="0.35" d="M5.25 5.99985H17.25" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
  	export default {
    	name: 'moduleD',
    	props: ['color'],
    }
</script>