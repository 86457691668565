<template>
  <MessageDialogue ref="messageDialogue" />
  <ConfirmDialogue ref="confirmDialogue" />
	<div class="content">
		<form @submit.prevent="onSubmit($event)">
      <div class="row">
        <div class="col-xs-12 col-sm-6 col-md-6">

          <div class="form-group disabled">
            <div class="form-control">
              <label> Управляющая организация </label>
              <input type="text" autocomplete="off" :value="organization.name">
            </div>
          </div>

          <div class="form-group" :class="{ 'error': form.login.error.status, 'disabled' : employee !== null }">
            <div class="form-control">
              <label> {{ form.login.label }} </label>
              <input type="text"  autocomplete="off" v-model="form.login.value">
            </div>
            <div class="form-error"> {{ form.login.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.password.error.status }" v-if="employee == null">
            <div class="form-control">
              <label> {{ form.password.label }} </label>
              <input type="password" autocomplete="off" v-model="form.password.value">
            </div>
            <div class="form-error"> {{ form.password.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.active.error.status}">
            <SelectPicker v-model="form.active.value" :list="form.active.list" :label="form.active.label" />
            <div class="form-error"> {{ form.active.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.lastname.error.status }">
            <div class="form-control">
              <label> {{ form.lastname.label }} </label>
              <input type="text"  autocomplete="off" v-model="form.lastname.value">
            </div>
            <div class="form-error"> {{ form.lastname.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.firstname.error.status }">
            <div class="form-control">
              <label> {{ form.firstname.label }} </label>
              <input type="text"  autocomplete="off" v-model="form.firstname.value">
            </div>
            <div class="form-error"> {{ form.firstname.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.middlename.error.status }">
            <div class="form-control">
              <label> {{ form.middlename.label }} </label>
              <input type="text"  autocomplete="off" v-model="form.middlename.value">
            </div>
            <div class="form-error"> {{ form.middlename.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.role.error.status }">
            <SelectPicker v-model="form.role.value" :list="form.role.list" :label="form.role.label" />
            <div class="form-error"> {{ form.role.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.cities.error.status }">
            <CheckboxesMultyPicker :list="form.cities.list" v-model="form.cities.value" :label="form.cities.label"/>
            <div class="form-error"> {{ form.cities.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.houses.error.status }">
            <CheckboxesMultyPicker :list="housesList" v-model="form.houses.value" :label="form.houses.label"/>
            <div class="form-error"> {{ form.houses.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.contact.error.status }">
            <div class="form-control">
              <label> {{ form.contact.label }} </label>
              <input type="text"  autocomplete="off" v-model="form.contact.value">
            </div>
            <div class="form-error"> {{ form.contact.error.message }} </div>
          </div>

          <div class="form-group" :class="{ 'error': form.function.error.status }">
            <CheckboxesPicker v-model="form.function.value" :list="form.function.list" :label="form.function.label" :max="7"/>
            <div class="form-error"> {{ form.function.error.message }} </div>
          </div>

          <div class="btn-group">
            <router-link 
              v-if="employee !== null"
              :to="{name: 'PageEmployeeMain', params: {organization_id: organization.id, employee_id: employee.id} }" 
              class="btn btn--lg btn--outline">
              <div class="btn--text"> Отменить </div>
            </router-link>
            <router-link 
              v-else
              :to="{name: 'PageOrganization', params: {organization_id: organization.id} }" 
              class="btn btn--lg btn--outline">
              <div class="btn--text"> Отменить </div>
            </router-link>
            <button class="btn btn--lg btn--default" type="submit">
              <img class="btn--ico btn--ico--left" src="../../assets/img/check-white.svg">
              <div class="btn--text"> Сохранить </div>
              <div class="btn--loader"></div>
            </button>
          </div>

				</div>
        <div class="col-xs-6 col-sm-6 col-md-6">
          <ImagePicker v-model="image" />
        </div>
			</div>
    </form>
	</div>
</template>

<script setup>
  import SelectPicker from '@/components/ux/SelectPicker.vue';
  import CheckboxesPicker from '@/components/ux/CheckboxesPicker.vue';
  import ImagePicker from '@/components/ux/ImagePicker.vue';
  import CheckboxesMultyPicker from '@/components/ux/CheckboxesMultyPicker.vue';

  import MessageDialogue from '@/components/modals/MessageDialogue.vue';
  import ConfirmDialogue from '@/components/modals/ConfirmDialogue.vue';
</script>

<script>
  export default {
    name: 'EditForm',
  	props: ['organization', 'employee', 'functions', 'roles', 'cities', 'houses'],
  	data: () => ({
      image: null,
      form: {
        login: {
          label: 'Логин',
          value: '',
          required: {
            status: true,
            message: 'Укажите логин',
          },
          validate: [
            { regexp: /^[a-zA-Z0-9]+$/, msg: 'Только из буквы и цифры' }, 
            { regexp: /^[a-zA-Z].*/, msg: 'Логин должен начинаться с буквы' }, 
            { regexp: /.{8,}/, msg: 'Минимум 8 символов' }, 
          ],
          error: {
            status: false,
            message: null,
          }
        },
        password: {
          label: 'Пароль',
          value: '',
          required: {
            status: true,
            message: 'Укажите пароль',
          },
          validate: [
            { regexp: /.{8,}/, msg: 'Минимум 8 символов' }, 
          ],
          error: {
            status: false,
            message: null,
          }
        },
        lastname: {
          label: 'Фамилия',
          value: '',
          required: {
            status: true,
            message: 'Укажите фамилию',
          },
          validate: [
            { regexp: /.{3,}/, msg: 'Минимум 3 символа' }, 
          ],
          error: {
            status: false,
            message: null,
          }
        },
        firstname: {
          label: 'Имя',
          value: '',
          required: {
            status: true,
            message: 'Укажите имя',
          },
          validate: [
            { regexp: /.{3,}/, msg: 'Минимум 3 символа' }, 
          ],
          error: {
            status: false,
            message: null,
          }
        },
        middlename: {
          label: 'Отчество',
          value: '',
          required: {
            status: false,
            message: 'Укажите отчество',
          },
          validate: [
            { regexp: /.{3,}/, msg: 'Минимум 3 символа' }, 
          ],
          error: {
            status: false,
            message: null,
          }
        },
        active: {
          label: 'Статус',
          value: null,
          list: [{id: true, name: 'Активен'}, {id: false, name: 'Не активен'}],
          required: {
            status: true,
            message: 'Выберите статус',
          },
          validate: [],
          error: {
            status: false,
            message: null,
          }
        },
        role: {
          label: 'Роль',
          value: null,
          list: [],
          required: {
            status: true,
            message: 'Выберите роль',
          },
          validate: [],
          error: {
            status: false,
            message: null,
          }
        },
        function: {
          label: 'Квалификация',
          value: [],
          list: [],
          required: {
            status: true,
            message: 'Выберите квалификацию',
          },
          validate: [],
          error: {
            status: false,
            message: null,
          }
        },
        cities: {
          label: 'Город',
          value: [],
          list: [],
          required: {
            status: false,
            message: 'Выберите города',
          },
          validate: [],
          error: {
            status: false,
            message: null,
          }
        },
        houses: {
          label: 'Дома',
          value: [],
          list: [],
          required: {
            status: false,
            message: 'Выберите дома',
          },
          validate: [],
          error: {
            status: false,
            message: null,
          }
        },
        contact: {
          label: 'Контакт',
          value: '',
          required: {
            status: false,
            message: 'Укажите контакт',
          },
          validate: [
            { regexp: /.{3,}/, msg: 'Минимум 3 символа' }, 
          ],
          error: {
            status: false,
            message: null,
          }
        },

      },
    }),

    watch: {

      // При изменении списка городов
      'form.cities.value': function (val) {

        const delIdx = [];

        this.form.houses.value.forEach((house_id, index) => {

          const idx = this.houses.findIndex((item) => { return item.id == house_id });

          if(!val.includes(this.houses[idx].city.id)) delIdx.push(index);

        });

        for (var i = delIdx.length -1; i >= 0; i--) this.form.houses.value.splice(delIdx[i],1);
          
      },

    },
 
    async created () {

      // При изменени скидываем ошибки  
      for(let key in this.form) {
        // Объявляем слежение за значением
        this.$watch('form.' + key + '.value', () => {
          this.form[key].error.status = false;
        })
      }


      this.form.role.list = this.roles;
      this.form.function.list = this.functions;
      this.form.cities.list = this.cities;



      // Форма работает в режиме редактирования дома
      if(this.employee !== null) {

        // Сразу наполняем данными
        this.form.login.value = this.employee.login;
        this.form.password.value = '12345678';
        this.form.lastname.value = this.employee.lastName;
        this.form.firstname.value = this.employee.firstName;
        this.form.middlename.value = this.employee.middleName;
        this.form.role.value = this.employee.roleId;
        this.form.active.value = this.employee.active;
        this.form.contact.value = this.employee.contact;
        this.image = this.userImage;

        // Собираем нужный массив функций
        const functions = [];
        this.employee.functions.forEach((item) => {
          functions.push(item.id);
        });
        this.form.function.value = functions;

        // Собираем нужный массив городов
        const cities = [];
        this.employee.cities.forEach((item) => {
          cities.push(item.id);
        });
        this.form.cities.value = cities;

        // Собираем нужный массив городов
        const houses = [];
        this.employee.houses.forEach((item) => {
          houses.push(item.id);
        });
        this.form.houses.value = houses;
      }

    },

    computed: {
      
      userImage () {
      
        return this.employee.imgLinks !== null && this.employee.imgLinks.length > 0 ? this.employee.imgLinks[0] : null;
      
      },

      housesList () {
        const res = [];
        this.houses.forEach ((house) => {
          if (this.form.cities.value.includes(house.city.id)) {
            res.push({ id: house.id, name: house.address });
          }
        });
        return res;
      },

    },

    methods: {

      // Создать сотрудника
      async createEmployee (param) {
  
        const r = await this.axios.post(this.$store.state.api_endpoint + '/employees/', param);
        // return r.data.id;
        
        this.$router.push({name: 'PageEmployeeMain', params: {organization_id: this.organization.id, employee_id: r.data.id}});
      },

      // Обновить информацию о сотруднике
      async updateEmployee (param) {
        
        const r = await this.axios.patch(this.$store.state.api_endpoint + '/employees/' + this.employee.id, param);

        const isNext = await this.$refs.confirmDialogue.show({
          message: 'Данные сохранены!',
          okButton: 'Продолжить',
          cancelButton: 'Остаться',
        });

        if(isNext) {
          this.$router.push({name: 'PageEmployeeMain', params: {organization_id: this.organization.id, employee_id: r.data.id}});
        }

      },

      async onSubmit (e) {

        let btn = e.submitter;
        btn.classList.add('btn--load');

        const param = {
          firstName: this.form.firstname.value ? this.form.firstname.value : null,
          lastName: this.form.lastname.value ? this.form.lastname.value : null,
          middleName: this.form.middlename.value ? this.form.middlename.value : null,
          active: this.form.active.value,
          companyId: this.organization.id,
          contractorId: 0,
          cities: this.form.cities.value,
          houses: this.form.houses.value,
          contact: this.form.contact.value ? this.form.contact.value : null,
          functions: this.form.function.value,
          role: this.form.role.value,
          images: this.image !== null ? [ this.image ] : null,
        }

        if(this.employee == null) {
          param.login = this.form.login.value;
          param.password = this.form.password.value;
        }

        // Если прошла валидация полей
        if(this.$helpers.validateFields(this.form)) {

          try {

            this.employee == null ? await this.createEmployee(param) : await this.updateEmployee(param);

          } catch (e) {

            this.$refs.messageDialogue.show({ message: e.message, okButton: 'Подтвердить' });

          }

        }

        // Выключается волчок
        btn.classList.remove('btn--load');
      },
    },
  }
</script>

<style scoped>
	.content {
		padding: 25px;
	}
	.btn-group {
		justify-content: space-between;
		margin-top: 30px;
	}
</style>