<template>

	<TopMenu :breadcrumbs="breadcrumbs"/>

	<Navigator />

	<div class="component-filter">
		<div class="range-picker">
			<DatePicker v-model="filter.begin" label="Дата от" :isClear="false" />
			<DatePicker v-model="filter.end" label="Дата до" :isClear="false" />
		</div>
		<div class="btn-group">
			<a class="btn btn--medium btn--default btn--inline" @click="saveFilter($event)">
				<div class="btn--text"> Применить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
			<a class="btn btn--medium btn--outline btn--inline" @click="clearFilter">
				<div class="btn--text"> Сбросить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
		</div>
	</div>

	<div class="load-container" :class="{'load' : isLoad}">
		<div class="block mt-1" v-if="link !== null">
			<div class="block__body">
				<div class="file">
					<img class="file__ico" src="@/assets/img/file-blue.svg">
					<div class="file__info">
						<div class="file__name">{{ link.name }}</div>
						<div class="file__bottom">
							<a class="file__link" :href="link.link">
								<span> Скачать </span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
    	<div class="empty-data" v-else>
    		Нет данных
    	</div>
	</div>

</template>

<script setup>
	import TopMenu from '@/components/TopMenu.vue';
	import Navigator from '@/components/reports/Navigator.vue';
	import DatePicker from '@/components/ux/DatePicker.vue';
</script>

<script>
  export default {
    
    name: 'PageReportsCompleteHouse',
    

    data: () => ({
      	breadcrumbs: [],
      	house: null,
      	filter: {
	      	begin: null,
	      	end: null,
      	},
      	link: null,
      	isLoad: false,
    }),
    
    computed: {
    	house_id () {
    		return this.$route.params.house_id;
    	},
    },

    created () {

    	this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
    	this.filter.end = this.$moment().format('YYYY-MM-DD');

    	this.loadData();

    	Promise.all([this.loadHouse()])
		    .then(() => {
		      	this.beforeRender();
		    })
		    .catch((e)=> {
		      	console.log(e);
		    })
    },
    methods: {

    	beforeRender () {
    		this.breadcrumbs = [
	        	{name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico},
	        	{name: this.house.address, link: ''},
	        ];
    	},

    	async clearFilter (e) {
	 			const btn = e.currentTarget;
	 			btn.classList.add('btn--load');
				this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
				this.filter.end = this.$moment().format('YYYY-MM-DD');
				await this.loadData();
	 			btn.classList.remove('btn--load');
    	},

    	async saveFilter (e) {
	 			const btn = e.currentTarget;
	 			btn.classList.add('btn--load');
				await this.loadData();
	 			btn.classList.remove('btn--load');
    	},

    	buildfilter () {
    		const param = new URLSearchParams({});
    		if(this.filter.begin !== null) {
        		param.set("dateFrom", this.filter.begin + 'T00:00:00.000000+00:00');
	        }
	        if(this.filter.end !== null) {
	        	param.set("dateTo", this.filter.end + 'T23:59:59.999999+00:00');
	        }
	        return param;
    	},

    	async loadHouse () {
      		this.house = await this.$store.dispatch('getHouse', {house_id: this.house_id});
    	},

    	async loadData () {
    		this.isLoad = true;
	        const data = this.buildfilter();
	        this.link = await this.$store.dispatch('getReportsHousePlan', {house_id: this.house_id, data: data});
    		this.isLoad = false;
    	},
    
    }
  }
</script>


<style scoped>
	
	.component-filter {
			background-color: #fff;
			padding: 15px;
			border-radius: 8px;
			display: flex;
	    flex-direction: row;
	    align-items: center;
	    gap: 10px;
	    justify-content: space-between;
	    position: relative;
	    margin-bottom: 10px;
	}
	.component-filter__item {
		flex: 1 1 0;
  		width: 0;
	}
	.component-filter a {
		width: fit-content;
	}

	.block__body {
	    padding: 20px 30px;
	}
		.block__header {
		font-size: 20px;
	}
	.block__body .file:not(:last-child) {
		margin-bottom: 20px;
	}


</style>