<template>
	<div class="load-container" :class="{'load' : isLoad}">
		<div class="block" v-if="data !== null && data.dates.length > 0">
			<div class="block__body">
				<BarChart :chartData="chartData" :options="options" cssClasses="chart"/>
			</div>
			<div class="block__footer">
				<div class="c-legend">
                    <div class="c-legend__item" v-for="(item, idx) in chartData.datasets" :key="idx">
                        <div class="c-legend__badge" :style="{backgroundColor: item.backgroundColor}"></div> 
                        {{ item.label }}
                    </div>
                </div>
			</div>
		</div>
		<div class="empty-data" v-else>
		  	Нет данных
		</div>
	</div>
</template>

<script setup>
    import { BarChart } from 'vue-chart-3';
    import { Chart, registerables} from 'chart.js';
	Chart.register(...registerables);
</script>


<script>
  	export default {
	    name: 'CompanyAnalyticsBar',
	    props: ['organization_id'],
        components: { BarChart },
	    data: () => ({
	    	isLoad: true,
	    	data: null,
	    	chartData: null,
	    	options: {
                maintainAspectRatio: false,
                responsive: true,
                // barThickness: 20,
                barPercentage: 0.7,
                plugins: {
                    tooltip: {
                        enabled: true,
                    },
                    legend: {
                        display: false,
                    },
                    datalabels: {
                        display: false,
                    }
                },
                scales: {
                    x: {
                        // offset: true,
                        ticks: {
                            font: {
                                size: 9,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    },
                    y: {
                        // offset: true,
                        ticks: {
                            font: {
                                size: 10,
                                weight: 700,
                                family: 'Inter',
                            },
                            color: '#0b2836',
                        }
                    }
                }
            },
	    }),
	    created () {
	      	Promise.all([this.loadData()])
	      		.then(() => {
	      		})
	      		.catch((e)=> {
	        		console.log(e);
	      		})
	      		.finally(() => {
	      			this.isLoad = false;
		  		});
	    },
	    methods: {
	    	async loadData () {
		        
		        this.data = null;
		        
		        // Подгружаем контент
		        const { data } = await this.axios.get(this.$store.state.api_endpoint + '/dashboard/diagram-company-director?company_id=' + this.organization_id);

		        this.chartData = {labels: data.dates, datasets: [
		        	{
	                    label: 'Сделано, %',
	                    data: data.persentDone,
	                    backgroundColor: '#008dd2',
	                    isVisible: true,
	                    disabledLabel: false,
	                },
	                {
	                    label: 'Просрочено, %',
	                    data: data.persentExpired,
	                    backgroundColor: '#e03712',
	                    isVisible: true,
	                    disabledLabel: false,
	                }
		        ]};

		        this.data = data;


		    },
	    },
	}
</script>

<style scoped>

	.c-legend__item {
		cursor: unset;
	}
	.block__body {
		padding: 20px;
	}
	.block__footer {
		justify-content: center;
    	border-top: none;
    	padding-top: 0px;
	}
	.chart {
		max-height: 300px;
	}

</style>