<template>
	<TopMenu :breadcrumbs="breadcrumbs"/>
	<Navigator />
	<div class="component-filter">
		<div class="range-picker">
			<DatePicker v-model="filter.begin" label="Дата от" :isClear="false" />
			<DatePicker v-model="filter.end" label="Дата до" :isClear="false" />
		</div>
		<div class="btn-group">
			<a class="btn btn--medium btn--default btn--inline" @click="saveFilter($event)">
				<div class="btn--text"> Применить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
			<a class="btn btn--medium btn--outline btn--inline" @click="clearFilter">
				<div class="btn--text"> Сбросить фильтры </div>
				<div class="btn--loader"> </div>
			</a>
		</div>
	</div>
	<div class="load-container" :class="{'load' : isLoad}">
	  <CollapseContainer 
	  	v-for="item in modules" 
	  	:key="item.id_module" 
	  	class="collapse--noborder"
	  	ref="module">
	    <template v-slot:header>
	    	<div class="c-ico" :style="{background: iconColor(item.percent).bg}">
					<component :is="item.num" :color="iconColor(item.percent).color"></component>
				</div>
				{{ item.name }} &nbsp; <span :style="{'color': iconColor(item.percent).color}"> {{ item.percent + '%' }} </span>
	    </template>
	    <template v-slot:body>
	    	<table class="table-custom">
	    		<tbody>
	    			<tr v-for="epic in item.epics" :key="epic.id_epic">
	    				<td> {{ epic.name }} </td>
	    				<td width="50%"> 
	    					<DoubleProgressBar 
									:percent="epic.doneP"
									:count_left="null"
									:count_right="null"
									color_left="#7ecd77"
									color_right="#e03712"
								/>
	    				</td>
	    			</tr>
	    		</tbody>
	    	</table>
	    </template>
	  </CollapseContainer>
	</div>
</template>

<script setup>

	import TopMenu from '@/components/TopMenu.vue';
	import Navigator from '@/components/reports/Navigator.vue';
	import DatePicker from '@/components/ux/DatePicker.vue';
  import CollapseContainer from '@/components/ux/CollapseContainer.vue'
	import DoubleProgressBar from '@/components/ux/DoubleProgressBar.vue';

  import A from '@/components/icons/modules/A.vue';
  import B from '@/components/icons/modules/B.vue';
  import C from '@/components/icons/modules/C.vue';
  import D from '@/components/icons/modules/D.vue';
  import E from '@/components/icons/modules/E.vue';
  import F from '@/components/icons/modules/F.vue';
  import G from '@/components/icons/modules/G.vue';
  import H from '@/components/icons/modules/H.vue';
  import I from '@/components/icons/modules/I.vue';

</script>

<script>

  export default {
    name: 'PageReportsSystemHouse',
    components: { A, B, C, D, E, F, G, H, I },
    data: () => ({
      	isLoad: false,
      	breadcrumbs: [],
      	house: null,
      	modules: [],
      	state: {
      		A: { action: 'review' },
      		B: { action: 'review' },
      		C: { action: 'review' },
      		D: { action: 'review' },
      		E: { action: 'review' },
      		F: { action: 'review' },
      		G: { action: 'review' },
      		H: { action: 'review' },
      		I: { action: 'review' },
      	},
      	filter: {
	      	begin: null,
	      	end: null,
      	},
    }),

    computed: {
    	house_id () {
    		return this.$route.params.house_id;
    	},
    	iconColor () {
    		return (percent) => {
    			if(percent >= 90) {
    				return {color: 'rgba(var(--cozh-green-code), 1)', bg: 'rgba(var(--cozh-green-code), .08)'};
    			}
					if(percent < 90 && percent > 70) {
    				return {color: 'rgba(var(--cozh-orange-code), 1)', bg: 'rgba(var(--cozh-orange-code), .08)'};
					}
					if(percent <= 70) {
    				return {color: 'rgba(var(--cozh-red-code), 1)', bg: 'rgba(var(--cozh-red-code), .08)'};
					}
    		}
    	},
    },
    created () {
    	this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
    	this.filter.end = this.$moment().format('YYYY-MM-DD');
			this.loadData();
    	Promise.all([this.loadHouse()])
		    .then(() => {
		      this.beforeRender();
		    })
		    .catch(()=> {})
    },
    methods: {
    	beforeRender () {
    		this.breadcrumbs = [
	        	{name: this.$route.meta.name, link: this.$route.matched[0].path, ico: this.$route.meta.ico},
	        	{name: this.house.address, link: ''},
	        ];
    	},

    	async clearFilter (e) {
		 		const btn = e.currentTarget;
		 		btn.classList.add('btn--load');
    		this.filter.begin = this.$moment().subtract(7, 'days').format('YYYY-MM-DD');
    		this.filter.end = this.$moment().format('YYYY-MM-DD');
    		await this.loadData();
		 		btn.classList.remove('btn--load');
    	},

    	async saveFilter (e) {
		 		const btn = e.currentTarget;
		 		btn.classList.add('btn--load');
	    	await this.loadData();
		 		btn.classList.remove('btn--load');
    	},

    	buildfilter () {
    		const param = new URLSearchParams({});
    		if(this.filter.begin !== null) {
      		param.set("begin", this.filter.begin + 'T00:00:00.802333+00:00');
        }
        if(this.filter.end !== null) {
        	param.set("end", this.filter.end + 'T23:59:59.802443+00:00');
        }
        return param;
    	},

    	async loadData () {
    		this.isLoad = true;
      	const param = this.buildfilter();
      	const {data} = await this.$store.dispatch('getAnaliticsSystemModules', {house_id: this.house_id, data: param});
      	this.modules = data;
    		this.isLoad = false;
    	},

    	async loadHouse () {
      		this.house = await this.$store.dispatch('getHouse', {house_id: this.house_id});
    	},
    }
  }
</script>

<style scoped>

	.collapse:not(:last-child) {
		margin-bottom: 5px;
	}

	.collapse:deep(.collapse__header) {
		padding: 10px;
		font-weight: 500;
	}

	.collapse:deep(.collapse__body) {
		padding: 0px;
	}

	.c-ico {
		height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #E9F1F4;
    margin-right: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
	}



	.component-filter {
		background-color: #fff;
		padding: 15px;
		border-radius: 8px;
		display: flex;
    	flex-direction: row;
    	align-items: center;
    	gap: 10px;
    	justify-content: space-between;
    	position: relative;
    	margin-bottom: 10px;
	}

	.component-filter__item {
		flex: 1 1 0;
  		width: 0;
	}
	.component-filter a {
		width: fit-content;
	}
	
</style>