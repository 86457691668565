<template>
	<svg width="23" height="20" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg">
		<path d="M20.5909 1V19M20.5909 1H1.5H2.33976L2.40909 19M20.5909 1H21.5M20.5909 19H21.5M20.5909 19H2.40909M2.40909 19H1.5M11.5 1V19" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
		<path opacity="0.35" d="M5.13477 10.7068L6.95295 12.5113L8.77113 10.7068M16.0439 8V12.5113M16.0439 8L14.2257 9.81906M16.0439 8L17.862 9.81906M6.95295 12.5113V8" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
</template>

<script>
  	export default {
    	name: 'moduleA',
    	props: ['color'],
    }
</script>