<template>
  <div class="c-card">
    <div class="c-card__left">
      <div class="c-card__image" :style="{backgroundImage:'url(' + mkdImage + ')'}"></div>
    </div>
    <div class="c-card__right">
      <div class="c-card__top">

        <div class="c-card__group">
          <div class="c-card__small"> г. {{ house.city}} </div>
          <div class="c-card__name"> {{ house.address }} </div>
          <router-link class="c-card__link link-default" :to="{name: 'PageTrackerHouse', params: {organization_id: company.id, house_id: house.id }}">
            <span>Подробнее</span>
            <img class="btn--ico btn--ico--right" src="@/assets/img/mark-right-blue.svg">
          </router-link>
        </div>

        <div class="c-card__manager" v-if="house.manager !== null">
          <div class="c-card__small"> Управляющий дома </div>
          <div class="c-card__fio"> {{ house.manager.name }} </div>
          <div class="c-card__phone"> {{ house.manager.contact }} </div>
        </div>


      </div>
      <div class="c-card__bottom">
        <div class="c-card__item">
          <div class="c-card__label"> Всего заданий </div>
          <div class="c-card__text"> {{ countAllTasks }} </div>
        </div>
        <div class="c-card__item">
          <div class="c-card__label"> Назначено </div>
          <div class="c-card__text"> {{ house.counter.assigned }} </div>
        </div>
        <div class="c-card__item">
          <div class="c-card__label"> Сделано </div>
          <div class="c-card__text"> {{ house.counter.done }} </div>
        </div>
        <div class="c-card__item">
          <div class="c-card__label"> Есть проблемы </div>
          <div class="c-card__text"> {{ house.counter.issue }} </div>
        </div>
        <div class="c-card__item">
          <div class="c-card__label"> Не сделано </div>
          <div class="c-card__text"> {{ house.counter.failed}} </div>
        </div>
        <div class="c-card__item">
          <div class="c-card__label"> Просрочено </div>
          <div class="c-card__text"> {{ house.counter.expired }} </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
  export default {
    name: 'HousePreview',
    props: ['house', 'company'],
    computed: {
      mkdImage () {
        return this.house.imageLink && this.house.imageLink !== null && this.house.imageLink.length > 0 ? this.house.imageLink[0] : '/img/plug.png';
      },
      countAllTasks () {
        let summ = 0;
        for (let key in this.house.counter) {
          summ += this.house.counter[key];
        }
        return summ;
      },
    },
  }
</script>

<style scoped>

  .c-card {
    background-color: var(--cozh-white);
    border-radius: 8px;
    padding: 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

  .c-card:not(:last-child) {
    margin-bottom: 5px;
  }

  .c-card__left {
    margin-right: 30px;

  }

  .c-card__right {
    width: 100%;
  }



  .c-card__image {
    width: 240px;
    aspect-ratio: 3/2;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #E9F1F4;


    border-radius: 8px;
    border: 1px solid #d9e4eb;
  }

  .c-card__name {
    font-size: 18px;
    font-weight: 500;
  }

  .c-card__link {
    margin-top: 5px;
    text-transform: uppercase;
    font-size: 11px;
    font-weight: 600;
    letter-spacing: .05em;
  }
  .c-card__link img {
    margin-bottom: 2px;
  }

  .c-card__top {
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 20px;
    position: relative;
  }

  .c-card__bottom {
    border-top: 1px solid var(--cozh-light-grey);
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 25px;
  }




  .c-card__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    flex-basis: 120px;
    position: relative;
  }

  .c-card__label {
    font-size: 11px;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.05em;
    margin-bottom: 2px;
    color: var(--cozh-dark-grey);
    text-transform: uppercase;
    white-space: nowrap;
  }

  .c-card__text {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.2;
  }


  .c-card__item:first-child {
    margin-right: 25px;
    flex-basis: auto;
  }
  .c-card__item:first-child::before {
    content: '';
    height: 100%;
    width: 1px;
    background-color: var(--cozh-light-grey);
    position: absolute;
    top: 0px;
    right: -25px;
  }



  .c-card__group {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .c-card__manager {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
  }
  .c-card__hint {
    color: var(--cozh-grey);
    font-size: 11px;
    font-weight: 600;
    white-space: nowrap;
    position: absolute;
    top: 2px;
    right: 0px;
  }
  .c-card__small {
    font-weight: 500;
    font-size: 13px;
    line-height: 1.23;
    opacity: .5;
  }
  .c-card__fio {
    font-size: 16px;
    line-height: 1.25;
    margin-top: 4px;
  }
  .c-card__phone {
    font-weight: 500;
    font-size: 16px;
    line-height: 1;
    color: var(--cozh-blue);
    margin-top: 5px;
  }

</style>